import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'
import devvjob from '../../assets/images/devvjob.png'


class Devvjob extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet title="devvjob | A software developer vacancy search engine | Asgaard Software" />
                <HeaderGeneric title="Devvjob" subtitle="A project by Asgaard Software" showHome="true" />
                <div id="main">
                    <section id="content" className="main">
                        <h2>Devvjob</h2>

                        <p>Devvjob is a search engine for software developer vacancies in the UK.</p>

                        <p>Asgaard Software created Devvjob for an entrepreneurial client. The client's circumstances were unusual;
                            an individual with experience of the recruitment industry wished
                            to use his domain knowledge of tech recruitment to establish
                            his own independent recruitment property.
                        </p>

                        <div className="image main">
                            <img src={devvjob} alt="devvjob.co.uk" />
                        </div>

                        <p>
                            The client's vision was to create a job search engine to aggregate the many thousands of IT job adverts that exist online at any time, and give priority to the most relevant to particular users. The brief was to create a <strong>Minimum Viable Product</strong> (MVP) to achieve this. We suggested narrowing the scope to focus on software development opportunities, and to analyse the listings to better understand their content, allowing the search engine to tailor relevancy scores and thereby giving it a unique selling point.
                        </p>

                        <h3> Project Architecture </h3>

                        <p>The client's background and interests added some constraints. The client wanted to explore this as a side project, with minimal ongoing costs.</p>
                        <p>To meet this constraint, we structured the project a little unusually. The web side is deployed to cheap shared hosting, and runs a very light RESTful API on top of a live database of job listings.</p>

                        <p>A large part of the project is offline. A crawler aggregates listings it finds online, in much the same way that Google or Bing crawl the web to discover content. The crawler puts its content into an offline database, and the listings are analysed using artificial intelligence and natural language processing. When they have been analysed, they are synchronised to the online database. The synchronisation is tightly optimised to push large numbers of records in 
                            batches.</p>

                        <p className='text-center'><a href='https://devvjob.co.uk' className='button'>Visit devvjob</a></p>

                    </section>
                </div>
            </Layout>
        )
    }
}

export default Devvjob